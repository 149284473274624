import {useMutation, useQueryClient} from 'react-query'
import axios from 'axios'
import {APP_URLS} from '../../config/api-urls'
import DataService from '../DataService'
import {forgotPassword, resetPasswordWithToken, resetPassword} from './authUrls'

const API_URL = process.env.REACT_APP_API_URL

const Login = () => {
  return useMutation(
    async (payload) => {
      const {username, password} = payload
      const {data} = await axios.post(API_URL + APP_URLS.login, {
        username: username,
        password: password,
      })
      return data
    },
    {
      onSuccess: (response) => {
        localStorage.setItem('accessToken', response.data.token.access)
        localStorage.setItem('user', JSON.stringify(response.data.user))
        localStorage.setItem('refreshToken', response.data.token.refresh)
        window.location = '/dashboard'
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )
}

const Logout = () => {
  return useMutation(
    async (refreshToken) => {
      const url = `auth/logout/`
      const data = await DataService.post(url, refreshToken)
      return data
    },
    {
      onSuccess: () => {
        localStorage.clear()
      },
      onError: () => {},
    }
  )
}

const Forgot_pass = () => {
  return useMutation(async (payload) => {
    const {email} = payload
    const {data} = await axios.post(API_URL + APP_URLS.fotgot, {
      email: email,
    })
    return data
  })
}

const Reset_pass_with_token = () => {
  return useMutation(async (payload) => {
    const {token, password} = payload
    const {data} = await axios.post(API_URL + `/auth/password/hard-reset/${token}/`, {
      password: password,
    })
    return data
  })
}

const Reset_password = () => {
  const queryClient = useQueryClient()
  return useMutation((client) => resetPassword(client), {
    onMutate: async (newClient) => {
      await queryClient.cancelQueries('clientsList')

      const prev = queryClient.getQueryData('clientsList')
      return {
        prev,
        newClient,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('clientsList')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('clientsList', context.prev)
    },
  })
}

const useLoginQuery = {
  Login,
  Logout,
  Forgot_pass,
  Reset_pass_with_token,
  Reset_password,
}

export default useLoginQuery
