import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { Toaster } from 'react-hot-toast'
import MyProvider from '../context/ContextProvider'

const App = () => {
  document.addEventListener('wheel', function (event) {
    //@ts-ignore
    if (document.activeElement.type === 'number') {
      //@ts-ignore
      document.activeElement.blur()
    }
  })
  return (

    <MyProvider>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <Outlet />
            <MasterInit />
            <Toaster position='top-right' reverseOrder={false} />
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </MyProvider>
  )
}

export { App }
