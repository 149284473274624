import DataService from '../DataService'

//Add Files to S3
const addFileToS3 = async (value) => {
  let url = `upload/s3/`
  const data = await DataService.form_post(url, value)
  return data.data
}

const getCountriesList = async () => {
  let url = `common/countries/?limit=200`
  const data = await DataService.get(url)
  return data.data
}

const getCountryListWsearch = async (query) => {
  let {search} = query
  let url = `common/countries/?search=${search}&limit=200`
  const data = await DataService.get(url)
  return data.data
}

const getCountryDetails = async (id) => {
  let url = `common/country/${id}/`
  const data = await DataService.get(url)
  return data.data
}

const addCountry = async (value) => {
  let url = `common/countries/`
  const data = await DataService.post(url, value)
  return data
}

const updateCountry = async (value) => {
  const {id, ...editData} = value
  const url = `common/country/${id}/`
  const response = await DataService.patch(url, value)
  return response
}

const deleteCountry = async (countryID) => {
  const url = `common/country/${countryID}/`
  const data = await DataService.del(url)
  return data
}

const getStatesList = async (id) => {
  let url = `common/states/?country=${id}`
  const data = await DataService.get(url)
  return data.data
}

const getStateListWsearch = async (query) => {
  let {search, state} = query
  let url = `common/states/?state=${state}&search=${search}&limit=200`
  const data = await DataService.get(url)
  return data.data
}

const getStateDetails = async (id) => {
  let url = `common/state/${id}/`
  const data = await DataService.get(url)
  return data.data
}

const addState = async (value) => {
  let url = `common/states/`
  const data = await DataService.post(url, value)
  return data
}

const updateState = async (value) => {
  const {id, ...editData} = value
  const url = `common/state/${id}/`
  const response = await DataService.patch(url, value)
  return response
}

const deleteState = async (stateID) => {
  const url = `common/state/${stateID}/`
  const data = await DataService.del(url)
  return data
}

const getCurrencyList = async (id) => {
  let url = `common/currencies/?limit=200`
  const data = await DataService.get(url)
  return data.data
}

const getCurrencyListWsearch = async (query) => {
  let {search} = query
  let url = `common/currencies/?search=${search}&limit=200`
  const data = await DataService.get(url)
  return data.data
}

const getCurrencyDetails = async (id) => {
  let url = `common/currency/${id}/`
  const data = await DataService.get(url)
  return data.data
}

const addCurrency = async (value) => {
  let url = `common/currencies/`
  const data = await DataService.post(url, value)
  return data
}

const updateCurrency = async (value) => {
  const {id, ...editData} = value
  const url = `common/currency/${id}/`
  const response = await DataService.patch(url, value)
  return response
}

const deleteCurrency = async (currencyID) => {
  const url = `common/currency/${currencyID}/`
  const data = await DataService.del(url)
  return data
}

const getUserProfile = async (id) => {
  let url = `auth/profile`
  const data = await DataService.get(url)
  return data.data
}

const updateProfile = async (value) => {
  const url = `auth/profile/`
  const response = await DataService.patch(url, value)
  return response
}

export {
  getCountriesList,
  getCountryListWsearch,
  getCountryDetails,
  addCountry,
  updateCountry,
  deleteCountry,
  getStatesList,
  getStateListWsearch,
  getStateDetails,
  addState,
  updateState,
  deleteState,
  getCurrencyList,
  getCurrencyListWsearch,
  getCurrencyDetails,
  addCurrency,
  updateCurrency,
  deleteCurrency,
  getUserProfile,
  updateProfile,
  addFileToS3
}
