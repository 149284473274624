/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { KTSVG } from '../../../_metronic/helpers'
import { Card5 } from '../../modules/widgets/Card5'

import { StatisticsWidget2, StatisticsWidget4 } from '../../../_metronic/partials/widgets'

import useDashboardQuery from '../../data/dashboard/useDashboardQuery'

const DashboardPage: FC = () => {
  const [user, setUser] = useState<any>(null)
  const [period, setPeriod] = useState('month')

  useEffect(() => {
    let data = localStorage.getItem('user')
    if (data) {
      setUser(JSON.parse(data))
    }
  }, [])
  const query = {
    period
  }

  const { data: dashboardData } = useDashboardQuery.Dashboard_details(query)
  const [data, setData] = useState<any>([])
  const [data2, setData2] = useState<any>([])

  useEffect(() => {
    if (dashboardData?.data[0] && dashboardData?.data[0]?.milestone[0]) {
      setData(dashboardData?.data[0]?.milestone)
    }

    if (dashboardData?.data) {
      setData2(dashboardData?.data)
    }
  }, [dashboardData])

  return (
    <>
      <div className='d-flex justify-content-end'>
        <select
          style={{ border: '1px solid black', width: '180px', borderRadius: '12px' }}
          onChange={(e) => setPeriod(e.target.value)}
          value={period}
          className={'form-select form-select-md'}
        >
          <option value='today'>Today</option>
          <option value='week'>This Week</option>
          <option value='month'>This Month</option>
          <option value='quarter'>This Quarter</option>
          <option value='year'>This Year</option>
        </select>
      </div>
      <div className='row g-5 g-xl-4 mt-4'>
        <div className='col-xl-4'>
          <StatisticsWidget2
            className='card-xl-stretch mb-xl-8'
            avatar='/media/dashboard/first.svg'
            title='View Projects'
            description={user?.user_type === 'finance' ? '' : 'On going projects'}
            description2={user?.user_type !== 'finance' ? 'Delayed projects' : ''}
            linkOne='/project-manager/ongoing/list'
            linkTwo='/project-manager/delayed/list'
            linkMain='/project-manager'
            bgColor='#2A3342'
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget2
            className='card-xl-stretch mb-xl-8'
            avatar='/media/dashboard/second.svg'
            title='View Milestones'
            description='Upcoming milestones'
            description2=''
            linkMain='/milestone-manager'
            linkOne='/milestone-manager/upcoming/list'
            bgColor='#06AAB3'
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget2
            className='card-xl-stretch mb-5 mb-xl-8'
            avatar='/media/dashboard/third.svg'
            title='View AMC’s'
            description='Upcoming AMC'
            description2='Pending AMC'
            linkMain='/amc-manager'
            linkOne='/amc-manager/upcoming/list'
            linkTwo='/amc-manager/pending/list'
          />
        </div>
      </div>

      {user?.user_type !== 'finance' && (
        <div className='d-flex flex-md-row flex-column gap-5'>

          {/* <div className='card-body bg-white col-md-8 col-12 h-100 '>
            <div className='p-5'>
              <span className='fs-4 fw-bold p-5'>Ongoing Project Status</span>
            </div>
            <div className='col-12 d-flex flex-md-row flex-column p-3 gap-2'>
              <div className='col-md col-12'>
                <div
                  className='d-flex align-items-center rounded p-5 mb-7'
                  style={{backgroundColor: '#F0F0F0'}}
                >
                  <span className='svg-icon svg-icon-warning me-5'>
                    <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
                  </span>
                  <div className='flex-grow-1 me-2'>
                    <span className='fw-bold text-gray-800 fs-6'>Kickoff Projects</span>
                  </div>
                  <span className='fw-bold text-dark py-1'>
                    {data.length > 0 && Object.values(data[0])}
                  </span>
                </div>
                <div
                  className='d-flex align-items-center rounded p-5 mb-7'
                  style={{backgroundColor: '#F0F0F0'}}
                >
                  <span className='svg-icon svg-icon-success me-5'>
                    <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
                  </span>
                  <div className='flex-grow-1 me-2'>
                    <span className='fw-bold text-gray-800 fs-6'>Comprehensive Analysis</span>
                  </div>
                  <span className='fw-bold text-dark py-1'>
                    {' '}
                    {data.length > 0 && Object.values(data[1])}
                  </span>
                </div>
                <div
                  className='d-flex align-items-center rounded p-5 mb-7'
                  style={{backgroundColor: '#F0F0F0'}}
                >
                  <span className='svg-icon svg-icon-warning me-5'>
                    <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
                  </span>
                  <div className='flex-grow-1 me-2'>
                    <span className='fw-bold text-gray-800 fs-6'>UX Completion</span>
                  </div>
                  <span className='fw-bold text-dark py-1'>
                    {' '}
                    {data.length > 0 && Object.values(data[2])}
                  </span>
                </div>
                <div
                  className='d-flex align-items-center rounded p-5 mb-7'
                  style={{backgroundColor: '#F0F0F0'}}
                >
                  <span className='svg-icon svg-icon-success me-5'>
                    <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
                  </span>
                  <div className='flex-grow-1 me-2'>
                    <span className='fw-bold text-gray-800 fs-6'>Release Plan Submission</span>
                  </div>
                  <span className='fw-bold text-dark py-1'>
                    {' '}
                    {data.length > 0 && Object.values(data[3])}
                  </span>
                </div>
              </div>

              <div className='col-md col-12'>
                <div
                  className='d-flex align-items-center rounded p-5 mb-7'
                  style={{backgroundColor: '#F0F0F0'}}
                >
                  <span className='svg-icon svg-icon-info me-5'>
                    <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
                  </span>
                  <div className='flex-grow-1 me-2'>
                    <span className='fw-bold text-gray-800 fs-6'>Release 1</span>
                  </div>
                  <span className='fw-bold text-dark py-1'>
                    {data.length > 0 && Object.values(data[4])}
                  </span>
                </div>
                <div
                  className='d-flex align-items-center rounded p-5 mb-7'
                  style={{backgroundColor: '#F0F0F0'}}
                >
                  <span className='svg-icon svg-icon-danger me-5'>
                    <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
                  </span>
                  <div className='flex-grow-1 me-2'>
                    <span className='fw-bold text-gray-800 fs-6'>Release 2</span>
                  </div>
                  <span className='fw-bold text-dark py-1'>
                    {data.length > 0 && Object.values(data[5])}
                  </span>
                </div>
                <div
                  className='d-flex align-items-center rounded p-5 mb-7'
                  style={{backgroundColor: '#F0F0F0'}}
                >
                  <span className='svg-icon svg-icon-info me-5'>
                    <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
                  </span>
                  <div className='flex-grow-1 me-2'>
                    <span className='fw-bold text-gray-800 fs-6'>Beta App Release in Staging</span>
                  </div>
                  <span className='fw-bold text-dark py-1'>
                    {data.length > 0 && Object.values(data[6])}
                  </span>
                </div>
                <div
                  className='d-flex align-items-center rounded p-5 mb-7'
                  style={{backgroundColor: '#F0F0F0'}}
                >
                  <span className='svg-icon svg-icon-danger me-5'>
                    <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
                  </span>
                  <div className='flex-grow-1 me-2'>
                    <span className='fw-bold text-gray-800 fs-6'>Production completed</span>
                  </div>
                  <span className='fw-bold text-dark py-1'>
                    {data.length > 0 && Object.values(data[7])}
                  </span>
                </div>
              </div>
            </div>
          </div> */}

          <div className='card-body bg-white col-md-8 col-12 h-100'
            style={{ borderRadius: '22px', marginTop: '13px', padding: '35px' }}>
            <div className='col-md-4 col-12 d-flex flex-row gap-4'>
            <div className='col-12'>
                <Card5
                  bgColor=""
                  className='card-xl-stretch mb-5 mb-xl-8'
                  avatar='/media/dashboard/flag.svg'
                  title='New Projects'
                  description={data2[3]?.new_projects}
                  statusDesc=''
                />
              </div>
              <div className='col-12'>
                <Card5
                  bgColor=""
                  className='card-xl-stretch mb-5 mb-xl-8'
                  avatar='/media/dashboard/cube.svg'
                  title='Upcoming Milestones'
                  description={data2[2]?.upcoming_milestones}
                  statusDesc=''
                />
              </div>
             
              <div className='col-12'>          
                  <Card5
                    bgColor=""
                    className='card-xl-stretch mb-5 mb-xl-8 '
                    avatar='/media/dashboard/timer.svg'
                    title='Pending AMC'
                    description={data2[1]?.pending_amc}
                    // statusDesc='Work Items'
                    statusDesc=''
                  />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
