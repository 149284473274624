import { useQuery, useQueryClient, useMutation } from 'react-query'
import {
  getCountriesList,
  getCountryListWsearch,
  addCountry,
  updateCountry,
  getCountryDetails,
  deleteCountry,
  getStatesList,
  getStateListWsearch,
  addState,
  updateState,
  getStateDetails,
  deleteState,
  getCurrencyList,
  getCurrencyListWsearch,
  getCurrencyDetails,
  addCurrency,
  updateCurrency,
  deleteCurrency,
  getUserProfile,
  updateProfile,
  addFileToS3
} from './utilUrls'


const Add_File_To_S3 = () => {
  return useMutation((payload) => addFileToS3(payload), {
    onMutate: async (newData) => {
      return {
        newData,
      }
    },
  })
}


const Countries_list = () => {
  return useQuery(['countriesList'], () => getCountriesList(), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Countries_list_with_search = (query) => {
  return useQuery(['countriesListWithSearch', query], () => getCountryListWsearch(query), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Country_details = (id) => {
  return useQuery(['countryDetails', id], () => getCountryDetails(id), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Add_country = () => {
  const queryClient = useQueryClient()
  return useMutation((country) => addCountry(country), {
    onMutate: async (newCountry) => {
      await queryClient.cancelQueries('countriesListWithSearch')

      const prev = queryClient.getQueryData('countriesListWithSearch')
      return {
        prev,
        newCountry,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('countriesListWithSearch')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('countriesListWithSearch', context.prev)
    },
  })
}

const Edit_country = () => {
  const queryClient = useQueryClient()
  return useMutation((country) => updateCountry(country), {
    onMutate: async (newCountry) => {
      await queryClient.cancelQueries('countriesListWithSearch')

      const prev = queryClient.getQueryData('countriesListWithSearch')
      return {
        prev,
        newCountry,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('countriesListWithSearch')
      queryClient.invalidateQueries('countryDetails')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('countriesListWithSearch', context.prev)
    },
  })
}

const DeleteCountry = () => {
  const queryClient = useQueryClient()
  return useMutation((countryID) => deleteCountry(countryID), {
    onMutate: async (todo) => {
      await queryClient.cancelQueries('countriesListWithSearch')
      const prev = queryClient.getQueryData('countriesListWithSearch')
      return {
        prev,
        todo,
      }
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('countriesListWithSearch', context.prev)
    },
    onSettled: () => {
      queryClient.invalidateQueries('countriesListWithSearch')
    },
  })
}

const States_list = (id) => {
  return useQuery(['statesList', id], () => getStatesList(id), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
    enabled: id !== undefined,
  })
}

const States_list_with_search = (query) => {
  return useQuery(['statesListWithSearch', query], () => getStateListWsearch(query), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const State_details = (id) => {
  return useQuery(['stateDetails', id], () => getStateDetails(id), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Add_state = () => {
  const queryClient = useQueryClient()
  return useMutation((state) => addState(state), {
    onMutate: async (newState) => {
      await queryClient.cancelQueries('statesListWithSearch')

      const prev = queryClient.getQueryData('statesListWithSearch')
      return {
        prev,
        newState,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('statesListWithSearch')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('statesListWithSearch', context.prev)
    },
  })
}

const Edit_state = () => {
  const queryClient = useQueryClient()
  return useMutation((state) => updateState(state), {
    onMutate: async (newState) => {
      await queryClient.cancelQueries('statesListWithSearch')

      const prev = queryClient.getQueryData('statesListWithSearch')
      return {
        prev,
        newState,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('statesListWithSearch')
      queryClient.invalidateQueries('stateDetails')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('statesListWithSearch', context.prev)
    },
  })
}

const DeleteState = () => {
  const queryClient = useQueryClient()
  return useMutation((stateID) => deleteState(stateID), {
    onMutate: async (todo) => {
      await queryClient.cancelQueries('statesListWithSearch')
      const prev = queryClient.getQueryData('statesListWithSearch')
      return {
        prev,
        todo,
      }
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('statesListWithSearch', context.prev)
    },
    onSettled: () => {
      queryClient.invalidateQueries('statesListWithSearch')
    },
  })
}

const Currencies_list = () => {
  return useQuery(['currenciesList'], () => getCurrencyList(), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Currencies_list_with_search = (query) => {
  return useQuery(['currenciesListWithSearch', query], () => getCurrencyListWsearch(query), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Currency_details = (id) => {
  return useQuery(['currencyDetails', id], () => getCurrencyDetails(id), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Add_currency = () => {
  const queryClient = useQueryClient()
  return useMutation((currency) => addCurrency(currency), {
    onMutate: async (newCurrency) => {
      await queryClient.cancelQueries('currenciesListWithSearch')

      const prev = queryClient.getQueryData('currenciesListWithSearch')
      return {
        prev,
        newCurrency,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('currenciesListWithSearch')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('currenciesListWithSearch', context.prev)
    },
  })
}

const Edit_currency = () => {
  const queryClient = useQueryClient()
  return useMutation((currency) => updateCurrency(currency), {
    onMutate: async (newCurrency) => {
      await queryClient.cancelQueries('currenciesListWithSearch')

      const prev = queryClient.getQueryData('currenciesListWithSearch')
      return {
        prev,
        newCurrency,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('currenciesListWithSearch')
      queryClient.invalidateQueries('currencyDetails')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('currenciesListWithSearch', context.prev)
    },
  })
}

const DeleteCurrency = () => {
  const queryClient = useQueryClient()
  return useMutation((currencyID) => deleteCurrency(currencyID), {
    onMutate: async (todo) => {
      await queryClient.cancelQueries('currenciesListWithSearch')
      const prev = queryClient.getQueryData('currenciesListWithSearch')
      return {
        prev,
        todo,
      }
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('currenciesListWithSearch', context.prev)
    },
    onSettled: () => {
      queryClient.invalidateQueries('currenciesListWithSearch')
    },
  })
}

const User_profile = () => {
  return useQuery(['userProfileDetails'], () => getUserProfile(), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Update_profile = () => {
  const queryClient = useQueryClient()
  return useMutation((profile) => updateProfile(profile), {
    onMutate: async (newProfile) => {
      await queryClient.cancelQueries('userProfileDetails')

      const prev = queryClient.getQueryData('userProfileDetails')
      return {
        prev,
        newProfile,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('userProfileDetails')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('userProfileDetails', context.prev)
    },
  })
}

const useUtilQuery = {
  Countries_list,
  Countries_list_with_search,
  Country_details,
  Add_country,
  Edit_country,
  DeleteCountry,
  States_list,
  States_list_with_search,
  State_details,
  Add_state,
  Edit_state,
  DeleteState,
  Currencies_list,
  Currencies_list_with_search,
  Currency_details,
  Add_currency,
  Edit_currency,
  DeleteCurrency,
  User_profile,
  Update_profile,
  Add_File_To_S3
}

export default useUtilQuery
