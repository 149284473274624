import Axios from 'axios'

export const http = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {Accept: 'application/json'},
})

http.interceptors.request.use(async (config) => {
  let accessToken = localStorage.getItem('accessToken')
  config.headers['authorization'] = `Bearer ${accessToken}`
  return config
})

http.interceptors.response.use(undefined, function (err) {
  var statusCode = err.status
  if (statusCode === undefined) {
    var lineSplit = err.toString().split('\n')[0].split(' ')
    statusCode = lineSplit[lineSplit.length - 1]
  }
  if (statusCode == 401) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    window.location.href = '/login'
  }
  if (statusCode == 400) {
    throw err?.response?.data?.message
  }
  if (statusCode == 500) {
    throw 'Server is facing technical issues. Please contact the administrator.'
  }
})

export const formData_instance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

formData_instance.interceptors.request.use(async (config) => {
  let accessToken = localStorage.getItem('accessToken')
  config.headers['authorization'] = `Bearer ${accessToken}`
  return config
})

http.interceptors.response.use(
  async (response) => {
    return response
  },
  async (error) => {
    return Promise.reject(error)
  }
)

formData_instance.interceptors.response.use(
  async (response) => {
    return response
  },
  async (error) => {
    return Promise.reject(error)
  }
)
