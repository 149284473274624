import DataService from '../DataService'

const forgotPassword = async (value) => {
  let url = `auth/password/forgot/`
  const data = await DataService.post(url, value)
  return data
}

const resetPasswordWithToken = async (value) => {
  const {token, ...editData} = value
  let url = `auth/password/hard-reset/{token}/`
  const data = await DataService.post(url, value)
  return data
}

const resetPassword = async (value) => {
  let url = `auth/password/reset/`
  const data = await DataService.post(url, value)
  return data
}

export {forgotPassword, resetPasswordWithToken, resetPassword}
