import React, { useState } from 'react'
import CustomContext from './CustomContext'

const ContextProvider = ({ children }) => {
    const [myState, setMyState] = useState(null);

    const updateState = (newValue) => {
        setMyState(newValue);

    }

    return (

        <CustomContext.Provider value={{ myState, updateState }}>
            {children}
        </CustomContext.Provider>
    )
}

export default ContextProvider