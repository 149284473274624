/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  className: string
  image: string
  title: string
  time: string
  description: string
  label2?: string
  value2?: string
}

const StatisticsWidget1: React.FC<Props> = ({
  className,
  image,
  title,
  time,
  description,
  label2,
  value2,
}) => {
  return (
    <div
      className={`card bgi-no-repeat ${className}`}
      style={{
        backgroundPosition: 'right top',
        backgroundSize: '30% auto',
        backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/' + image)})`,
      }}
    >
      {/* begin::Body */}
      <div className='card-body'>
        <a href='#' className='card-title fw-bold text-muted text-hover-primary fs-4'></a>

        <div className='fw-bold text-primary my-6'>{time}</div>

        <div className='d-flex flex-row'>
          <div className='col-6'>
            <p
              className='text-dark-75 fw-semibold fs-6 m-0'
              dangerouslySetInnerHTML={{__html: description}}
            ></p>
          </div>
          <div className={`col-6  ${description === 'Address' ? 'text-start' : 'text-end'}`}>
            <p className='text-dark-75 fw-bold fs-6 m-0'>
              {description === 'Address' ? title?.slice(0, 100) : title}
            </p>
          </div>
        </div>
        <div className='d-flex flex-row mt-10'>
          <div className='col-3'>
            <p className='text-dark-75 fw-semibold fs-6 m-0'>{label2}</p>
          </div>
          <div className='col-9 text-end'>
            <p className='text-dark-75 fw-bold fs-6 m-0'>{value2}</p>
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {StatisticsWidget1}
