import clsx from 'clsx'
import React, {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'

import ResetUserPassword from '../../../../app/pages/auth/components/ResetUserPassword'

import {UploadImage} from '../../../partials/layout/header-menus/UploadImage'

import useUtilQuery from '../../../../app/data/util/useUtilQuery'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
  const [toggleReset, setToggleReset] = React.useState(false)
  const [toggleUploadImage, setToggleUploadImage] = React.useState(false)

  const [user, setUser] = React.useState<any>(null)

  const {data: profileData} = useUtilQuery.User_profile()

  React.useEffect(() => {
    let data = localStorage.getItem('user')
    if (data) {
      setUser(JSON.parse(data))
    }
  }, [])

  const {config} = useLayout()

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}></div>

      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}></div>

      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}></div>

      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}></div>

      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <div className='d-flex flex-column align-items-end me-3'>
          
          <span className='fw-bold text-muted text-hover-primary fs-7'>
            {profileData?.data?.name || '--'}
          </span>
          <span className='fw-bold text-muted text-hover-primary fs-7'>
            {profileData?.data?.user_type || '--'}
          </span>
        </div>
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img
            src={profileData?.data?.profile_picture}
            alt='metronic'
          />
        </div>
        <HeaderUserMenu setToggle={setToggleReset} setToggleUploadImage={setToggleUploadImage} />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {/* {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )} */}
      {toggleReset && <ResetUserPassword setToggle={setToggleReset} />}
      {toggleUploadImage && <UploadImage setToggle={setToggleUploadImage} />}
    </div>
  )
}

export {Topbar}
