import { useQuery } from 'react-query'
import { useMutation, useQueryClient } from 'react-query'
import {
  getProjectsList,
  getProjectMilestone,
  getProjectCodList,
  getProjectCodData,
  getProjectAMCList,
  getProjectMinutesList,
  getProjectKanbanList,
  getProjectDetails,
  getProjectMilestoneDetails,
  getProjectCodDetails,
  getProjectAMCDetails,
  getProjectMinuteDetails,
  addProject,
  addProjectMilestone,
  addProjectCod,
  addProjectAMC,
  addProjectMinute,
  updateProject,
  updateProjectMilestone,
  updateProjectProposal,
  updatePartialProjectMilestone,
  updateProjectCod,
  updateProjectAMC,
  updateProjectMinute,
  deleteProject,
  deleteProjectMilestone,
  deleteProjectCod,
  deleteProjectAMC,
  deleteProjectMinute,
  getProjectHoldStatus,
  addProjectHoldStatus,
} from './projectUrls'

const Projects_list = (query) => {
  return useQuery(['projectsList', query], () => getProjectsList(query), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Project_milestone_list = (id, query) => {
  return useQuery(['projectMilestoneList', id, query], () => getProjectMilestone(id, query), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Project_cod_list = (id, query) => {
  return useQuery(['projectCodList', id, query], () => getProjectCodList(id, query), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Project_cod_data = (id, query) => {
  return useQuery(['projectCodData', id, query], () => getProjectCodData(id, query), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Project_amc_list = (id, query) => {
  return useQuery(['projectAmcList', id, query], () => getProjectAMCList(id, query), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Project_minute_list = (id) => {
  return useQuery(['projectMinuteList', id], () => getProjectMinutesList(id), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Project_kanban_list = (query) => {
  return useQuery(['projectKanbanList', query], () => getProjectKanbanList(query), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Project_details = (id) => {
  return useQuery(['projectDetails', id], () => getProjectDetails(id), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Project_milestone_details = (id) => {
  return useQuery(['projectMilestoneDetails', id], () => getProjectMilestoneDetails(id), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Project_cod_details = (id) => {
  return useQuery(['projectCodDetails', id], () => getProjectCodDetails(id), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Project_amc_details = (id) => {
  return useQuery(['projectAmcDetails', id], () => getProjectAMCDetails(id), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Project_minute_details = (id) => {
  return useQuery(['projectMinuteDetails', id], () => getProjectMinuteDetails(id), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Add_project = () => {
  const queryClient = useQueryClient()
  return useMutation((project) => addProject(project), {
    onMutate: async (newProject) => {
      await queryClient.cancelQueries('projectsList')

      const prev = queryClient.getQueryData('projectsList')
      return {
        prev,
        newProject,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('projectsList')
      queryClient.invalidateQueries('clientProjectsList')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('projectsList', context.prev)
    },
  })
}

const Add_project_milestone = () => {
  const queryClient = useQueryClient()
  return useMutation((milestone) => addProjectMilestone(milestone), {
    onMutate: async (newMilestone) => {
      await queryClient.cancelQueries('projectMilestoneList')

      const prev = queryClient.getQueryData('projectMilestoneList')
      return {
        prev,
        newMilestone,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('projectMilestoneList')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('projectMilestoneList', context.prev)
      // console.log("ERROR", err)
    },
  })
}

const Add_project_cod = () => {
  const queryClient = useQueryClient()
  return useMutation((cod) => addProjectCod(cod), {
    onMutate: async (newCod) => {
      await queryClient.cancelQueries('projectCodList')

      const prev = queryClient.getQueryData('projectCodList')
      return {
        prev,
        newCod,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('projectCodList')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('projectCodList', context.prev)
    },
  })
}

const Add_project_amc = () => {
  const queryClient = useQueryClient()
  return useMutation((amc) => addProjectAMC(amc), {
    onMutate: async (newCod) => {
      await queryClient.cancelQueries('projectAmcList')

      const prev = queryClient.getQueryData('projectAmcList')
      return {
        prev,
        newCod,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('projectAmcList')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('projectAmcList', context.prev)
    },
  })
}

const Add_project_minute = () => {
  const queryClient = useQueryClient()
  return useMutation((amc) => addProjectMinute(amc), {
    onMutate: async (newCod) => {
      await queryClient.cancelQueries('projectMinuteList')

      const prev = queryClient.getQueryData('projectMinuteList')
      return {
        prev,
        newCod,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('projectMinuteList')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('projectMinuteList', context.prev)
    },
  })
}

const Edit_project = () => {
  const queryClient = useQueryClient()
  return useMutation((project) => updateProject(project), {
    onMutate: async (newProject) => {
      await queryClient.cancelQueries('projectsList')

      const prev = queryClient.getQueryData('projectsList')
      return {
        prev,
        newProject,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('projectsList')
      queryClient.invalidateQueries('clientProjectsList')
      queryClient.invalidateQueries('projectDetails')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('projectsList', context.prev)
    },
  })
}

const Edit_project_milestone = () => {
  const queryClient = useQueryClient()
  return useMutation((milestone) => updateProjectMilestone(milestone), {
    onMutate: async (newMS) => {
      await queryClient.cancelQueries('projectMilestoneList')

      const prev = queryClient.getQueryData('projectMilestoneList')
      return {
        prev,
        newMS,
      }
    },
    
    onSettled: () => {
      queryClient.invalidateQueries('projectMilestoneList')
      queryClient.invalidateQueries('projectMilestoneDetails')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('projectMilestoneList', context.prev)
    },
  })
}

const Edit_project_proposal = () => {
  const queryClient = useQueryClient()
  return useMutation((proposal) => updateProjectProposal(proposal), {
    onMutate: async (newMS) => {
      await queryClient.cancelQueries('projectProposalList')

      const prev = queryClient.getQueryData('projectProposalList')
      return {
        prev,
        newMS,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('projectProposalList')
      queryClient.invalidateQueries('projectPRoposalDetails')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('projectProposalList', context.prev)
    },
  })
}


const Edit_partial_project_milestone = () => {
  const queryClient = useQueryClient()
  return useMutation((value) => updatePartialProjectMilestone(value), {
    onMutate: async (newMS) => {
      await queryClient.cancelQueries('projectsList')

      const prev = queryClient.getQueryData('projectsList')
      return {
        prev,
        newMS,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('projectsList')
      queryClient.invalidateQueries('projectsList')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('projectsList', context.prev)
    },
  })
}

const Edit_project_cod = () => {
  const queryClient = useQueryClient()
  return useMutation((cod) => updateProjectCod(cod), {
    onMutate: async (newMS) => {
      await queryClient.cancelQueries('projectCodList')

      const prev = queryClient.getQueryData('projectCodList')
      return {
        prev,
        newMS,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('projectCodList')
      queryClient.invalidateQueries('projectCodDetails')
    },
    onSuccess: () => {
      queryClient.invalidateQueries('projectCodData')
      
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('projectCodList', context.prev)
    },
  })
}

const Edit_project_amc = () => {
  const queryClient = useQueryClient()
  return useMutation((amc) => updateProjectAMC(amc), {
    onMutate: async (newAMC) => {
      await queryClient.cancelQueries('projectAmcList')

      const prev = queryClient.getQueryData('projectAmcList')
      return {
        prev,
        newAMC,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('projectAmcList')
      queryClient.invalidateQueries('projectAmcDetails')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('projectAmcList', context.prev)
    },
  })
}

const Edit_project_minute = () => {
  const queryClient = useQueryClient()
  return useMutation((amc) => updateProjectMinute(amc), {
    onMutate: async (newAMC) => {
      await queryClient.cancelQueries('projectMinuteList')

      const prev = queryClient.getQueryData('projectMinuteList')
      return {
        prev,
        newAMC,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('projectMinuteList')
      queryClient.invalidateQueries('projectMinuteDetails')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('projectMinuteList', context.prev)
    },
  })
}

const DeleteProject = () => {
  const queryClient = useQueryClient()
  return useMutation((projectID) => deleteProject(projectID), {
    onMutate: async (todo) => {
      await queryClient.cancelQueries('projectsList')
      const prev = queryClient.getQueryData('projectsList')
      return {
        prev,
        todo,
      }
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('projectsList', context.prev)
    },
    onSettled: () => {
      queryClient.invalidateQueries('projectsList')
      queryClient.invalidateQueries('clientProjectsList')
    },
  })
}

const DeleteProjectMilestone = () => {
  const queryClient = useQueryClient()
  return useMutation((milestoneID) => deleteProjectMilestone(milestoneID), {
    onMutate: async (todo) => {
      await queryClient.cancelQueries('projectMilestoneList')
      const prev = queryClient.getQueryData('projectMilestoneList')
      return {
        prev,
        todo,
      }
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('projectMilestoneList', context.prev)
    },
    onSettled: () => {
      queryClient.invalidateQueries('projectMilestoneList')
    },
  })
}

const DeleteProjectCod = () => {
  const queryClient = useQueryClient()
  return useMutation((id) => deleteProjectCod(id), {
    onMutate: async (todo) => {
      await queryClient.cancelQueries('projectCodList')
      const prev = queryClient.getQueryData('projectCodList')
      return {
        prev,
        todo,
      }
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('projectCodList', context.prev)
    },
    onSettled: () => {
      queryClient.invalidateQueries('projectCodList')
    },
  })
}

const DeleteProjectAmc = () => {
  const queryClient = useQueryClient()
  return useMutation((id) => deleteProjectAMC(id), {
    onMutate: async (todo) => {
      await queryClient.cancelQueries('projectAmcList')
      const prev = queryClient.getQueryData('projectAmcList')
      return {
        prev,
        todo,
      }
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('projectAmcList', context.prev)
    },
    onSettled: () => {
      queryClient.invalidateQueries('projectAmcList')
    },
  })
}

const DeleteProjectMinute = () => {
  const queryClient = useQueryClient()
  return useMutation((id) => deleteProjectMinute(id), {
    onMutate: async (todo) => {
      await queryClient.cancelQueries('projectMinuteList')
      const prev = queryClient.getQueryData('projectMinuteList')
      return {
        prev,
        todo,
      }
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('projectMinuteList', context.prev)
    },
    onSettled: () => {
      queryClient.invalidateQueries('projectMinuteList')
    },
  })
}

const Projects_hold_status = (id) => {
  return useQuery(['projectHoldStatus', id], () => getProjectHoldStatus(id), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const Add_project_hold_status = () => {
  const queryClient = useQueryClient()
  return useMutation((id) => addProjectHoldStatus(id), {
    onMutate: async (newStatus) => {
      await queryClient.cancelQueries('projectHoldStatus')

      const prev = queryClient.getQueryData('projectHoldStatus')
      return {
        prev,
        newStatus,
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries('projectHoldStatus')
    },
    onError: (err, _, context) => {
      queryClient.setQueryData('projectHoldStatus', context.prev)
    },
  })
}

const useProjectsQuery = {
  Projects_list,
  Project_milestone_list,
  Project_cod_list,
  Project_cod_data,
  Project_amc_list,
  Project_minute_list,
  Project_kanban_list,
  Project_details,
  Project_milestone_details,
  Project_cod_details,
  Project_amc_details,
  Project_minute_details,
  Add_project,
  Add_project_milestone,
  Add_project_cod,
  Add_project_amc,
  Add_project_minute,
  Edit_project,
  Edit_project_milestone,
  Edit_project_proposal,
  Edit_partial_project_milestone,
  Edit_project_cod,
  Edit_project_amc,
  Edit_project_minute,
  DeleteProject,
  DeleteProjectMilestone,
  DeleteProjectCod,
  DeleteProjectAmc,
  DeleteProjectMinute,
  Projects_hold_status,
  Add_project_hold_status,
}

export default useProjectsQuery
