import {useQuery} from 'react-query'
import {getDashboardDetails} from './dashboardUrls'

const Dashboard_details = (query) => {
  return useQuery(['dashboardDetails',query], () => getDashboardDetails(query), {
    staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    poll: 500,
  })
}

const useDashboardQuery = {
  Dashboard_details,
}

export default useDashboardQuery
