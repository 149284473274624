import DataService from '../DataService'

const getProjectsList = async (query) => {
  const { search, limit, offset, on_going, delayed, date, project_type } = query
  let url = `projects/?limit=${limit}&offset=${offset}&search=${search}&date=${date}&project_type=${project_type}&on_going=${on_going ? 'True' : ''
    }&delayed=${delayed ? 'True' : ''}`
  const data = await DataService.get(url)
  return data.data
}

const getProjectMilestone = async (id, query) => {
  const { search, limit, offset, status, milestone_status, date } = query
  let url = `project/${id}/milestones/?status=${status}&milestone_status=${milestone_status}&limit=${limit}&offset=${offset}&search=${search}&date=${date}`
  const data = await DataService.get(url)
  return data.data
}

const getProjectCodList = async (id, query) => {
  const { search, limit, offset, proposal_type, date } = query
  let url = `project/cods-search/?proposal_type=${proposal_type}&date=${date}&limit=${limit}&offset=${offset}&search=${search}&project=${id}`
  const data = await DataService.get(url)
  return data.data
}

const getProjectCodData = async (id, query) => {
  const { search, limit, offset, proposal_type, date } = query
  let url = `project/cods-search/?proposal_type=cod&date=${date}&limit=${limit}&offset=${offset}&search=${search}&project=${id}`
  const data = await DataService.get(url)
  return data.data
}
const getProjectAMCList = async (id, query) => {
  // const { status, search, date } = query
  // ?status=${status}&date=${date}&limit=200&search=${search}
  let url = `project/${id}/amc/?date=${query.date}&limit=200`
  const data = await DataService.get(url)
  return data.data
}

const getProjectMinutesList = async (id) => {
  let url = `project/${id}/minutes/?limit=200`
  const data = await DataService.get(url)
  return data.data
}

const getProjectKanbanList = async ({ date, project_type }) => {
  let url = `project/kanban/?limit=200&date=${date}&project_type=${project_type}`
  const data = await DataService.get(url)
  return data.data
}

const getProjectDetails = async (id) => {
  let url = `project/${id}/`
  const data = await DataService.get(url)
  return data.data
}

const getProjectMilestoneDetails = async (id) => {
  let url = `project/milestone/${id}/`
  const data = await DataService.get(url)
  return data.data
}

const getProjectCodDetails = async (id) => {
  let url = `project/cod/${id}/`
  const data = await DataService.get(url)
  // console.log("COD DETAILS", data)
  return data.data
}

const getProjectAMCDetails = async (id) => {
  let url = `project/amc/${id}/`
  const data = await DataService.get(url)
  return data.data
}

const getProjectMinuteDetails = async (values) => {
  const { projectID, id } = values
  let url = `project/${projectID}/minute/${id}/`
  const data = await DataService.get(url)
  return data.data
}

const addProject = async (value) => {
  let url = `projects/`
  const data = await DataService.post(url, value)
  return data
}

const addProjectMilestone = async (value) => {
  const { project, ...editData } = value
  let url = `project/${project}/create-milestone`
  const data = await DataService.post(url, value)
  // console.log("data", data)
  return data
}

const addProjectCod = async (value) => {
  const { id, ...editData } = value
  let url = `project/${id}/cod/`
  const data = await DataService.post(url, value)
  return data
}

const addProjectAMC = async (value) => {
  const { id, ...editData } = value
  let url = `project/${id}/amc/`
  const data = await DataService.post(url, value)
  return data
}

const addProjectMinute = async (value) => {
  const { id, ...editData } = value
  let url = `project/${id}/minutes/`
  const data = await DataService.post(url, editData)
  return data
}

const updateProject = async (value) => {
  const { id, ...editData } = value
  const url = `project/${id}/`
  const response = await DataService.patch(url, value)
  return response
}

const updateProjectMilestone = async (value) => {
  const { id, ...editData } = value
  const url = `project/milestone/${id}/`
  const response = await DataService.patch(url, value)
  return response
}

const updateProjectProposal = async (value) => {
  const { id, ...editData } = value
  const url = `project/${id}/cod`
  const response = await DataService.patch(url, value)
  return response
}

const updatePartialProjectMilestone = async (value) => {
  const { id, milestone } = value
  const url = `project/${id}/kanban/milestone/${milestone}/`
  const response = await DataService.patch(url)
  return response
}

const updateProjectCod = async (value) => {
  const { id, ...editData } = value
  const url = `project/cod/${id}/`
  const response = await DataService.patch(url, value)
  return response
}

const updateProjectAMC = async (value) => {
  const { id, ...editData } = value
  const url = `project/amc/${id}/`
  const response = await DataService.patch(url, value)
  return response
}

const updateProjectMinute = async (value) => {
  const { projectID, id, ...editData } = value
  const url = `project/${projectID}/minute/${id}/`
  const response = await DataService.patch(url, value)
  return response
}

const deleteProject = async (clientID) => {
  const url = `project/${clientID}/`
  const data = await DataService.del(url)
  return data
}

const deleteProjectMilestone = async (clientID) => {
  const url = `project/milestone/${clientID}/`
  const data = await DataService.del(url)
  return data
}

const deleteProjectCod = async (id) => {
  const url = `project/cod/${id}/`
  const data = await DataService.del(url)
  return data
}

const deleteProjectAMC = async (id) => {
  const url = `project/amc/${id}/`
  const data = await DataService.del(url)
  return data
}

const deleteProjectMinute = async (values) => {
  const { projectID, id } = values
  const url = `project/${projectID}/minute/${id}/`
  const data = await DataService.del(url)
  return data
}

const getProjectHoldStatus = async (id) => {
  let url = `project/${id}/hold/`
  const data = await DataService.get(url)
  return data.data
}

const addProjectHoldStatus = async (id) => {
  let url = `project/${id}/hold/`
  const data = await DataService.post(url)
  return data
}

export {
  getProjectsList,
  getProjectMilestone,
  getProjectCodList,
  getProjectCodData,
  getProjectAMCList,
  getProjectMinutesList,
  getProjectKanbanList,
  getProjectDetails,
  getProjectMilestoneDetails,
  getProjectCodDetails,
  getProjectAMCDetails,
  getProjectMinuteDetails,
  addProject,
  addProjectMilestone,
  addProjectCod,
  addProjectAMC,
  addProjectMinute,
  updateProject,
  updateProjectMilestone,
  updateProjectProposal,
  updatePartialProjectMilestone,
  updateProjectCod,
  updateProjectAMC,
  updateProjectMinute,
  deleteProject,
  deleteProjectMilestone,
  deleteProjectCod,
  deleteProjectAMC,
  deleteProjectMinute,
  getProjectHoldStatus,
  addProjectHoldStatus,
}
