/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  const [user, setUser] = React.useState<any>(null)

  React.useEffect(() => {
    let data = localStorage.getItem('user')
    if (data) {
      setUser(JSON.parse(data))
    }
  }, [])

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {user &&
        (user.user_type === 'admin' ||
          user.user_type === 'sales' ||
          user.user_type === 'pre_sales') && (
          <AsideMenuItem
            to='/client-manager'
            title='Client Manager'
            icon='/media/icons/duotune/general/gen051.svg'
            fontIcon='bi-layers'
          />
        )}
      <AsideMenuItem
        to='/project-manager'
        title='Project Manager'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/milestone-manager'
        icon='/media/icons/duotune/general/gen005.svg'
        title='Milestone Manager'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/amc-manager'
        title='AMC Manager'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      />
      {user && user.user_type === 'admin' && (
        <AsideMenuItem
          to='/user-manager'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-person'
          title='User Manager'
        />
      )}

      {user && user.user_type === 'admin' && (
        <AsideMenuItem
          to='/settings/currency'
          icon='/media/icons/duotune/coding/cod001.svg'
          fontIcon='bi-person'
          title='Settings'
        />
      )}
    </>
  )
}
