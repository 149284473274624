import {useEffect, useState, FC} from 'react'
import {KTSVG} from '../../../helpers'
import {toast} from 'react-hot-toast'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'

import useLoginQuery from '../../../data/auth/useAuthQuery'

type Props = {
  setToggle: Function
}

const loginSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(10, 'Maximum 10 symbols')
    .required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password is required'),
})

const initialValues = {
  password: '',
  confirm_password: '',
}

const ResetUserPassword: FC<Props> = ({setToggle}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const {mutate: resetPassword, isSuccess} = useLoginQuery.Reset_password()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        if (values.password === values.confirm_password) {
          //@ts-ignore
          await resetPassword(values)
        }
      } catch (error) {
        console.error(error)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    if (isSuccess) {
      toast.success('Password changed successfully')
      setToggle(false)
      localStorage.clear()
      const win: Window = window
      win.location = '/auth'
    }
  }, [isSuccess])

  return (
    <>
      <div
        className='modal fade show d-block w-100 h-100'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-550px'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h2 className='fw-bolder'>Reset Password</h2>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => setToggle(false)}
                style={{cursor: 'pointer'}}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
              </div>
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-5 my-2'>
              <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
              >
                <div className='fv-row mb-10'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Password</label>
                  <input
                    placeholder='Password'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {'is-invalid': formik.touched.password && formik.errors.password},
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                    type='password'
                    name='password'
                    autoComplete='off'
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className='fv-row'>
                  <div className='d-flex justify-content-between mt-n5'>
                    <div className='d-flex flex-stack mb-2'>
                      <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                        Confirm Password
                      </label>
                    </div>
                  </div>
                  <input
                    type='confirm_password'
                    placeholder='Confirm Password'
                    autoComplete='off'
                    {...formik.getFieldProps('confirm_password')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid':
                          formik.touched.confirm_password && formik.errors.confirm_password,
                      },
                      {
                        'is-valid':
                          formik.touched.confirm_password && !formik.errors.confirm_password,
                      }
                    )}
                  />
                  {formik.touched.confirm_password && formik.errors.confirm_password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.confirm_password}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='text-center mt-10'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-lg btn-primary w-100 mb-5'
                    // disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Continue</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default ResetUserPassword
