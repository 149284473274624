import DataService from '../DataService'

const getDashboardDetails = async (query) => {
  const {period} =query
  let url = `dashboard/?period=${period}`
  const data = await DataService.get(url)
  return data.data
}

export {getDashboardDetails}
