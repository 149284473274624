/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { toAbsoluteUrl } from '../../helpers'
import { Card } from '../../pages/project-manager/project-details/overview/Card'

type Props = {
  title: string
  description: string
  statusDesc: string
  className: string
  avatar: string
  bgColor: string
}

const Card5: FC<Props> = ({ title, className, bgColor, avatar, description, statusDesc }) => {
  return (
    <div className={`card mb-4 ${className}`} style={{ backgroundColor: bgColor ? bgColor : 'white' }}>

      <div className='card-body d-flex flex-column px-9'
        style={{ backgroundColor: bgColor ? bgColor : '#EDEDED', borderRadius: '15px' }}>
        <div className='card-title m-0 mt-3'>
          <div className='div d-flex flex-item-between'>
            <span className='col-9 fs-4 fw-bold text-gray-600 m-0'>{title}</span>
            <div className='col-3 fs-2tx fw-bolder text-end'>{description}</div>
          </div>
        </div>
        <div className='p-0'>
          <div className='card-title mt-2 p-2'
            style={{
              backgroundColor: bgColor ? bgColor : '#FFFFFF',
              borderRadius: '12px',
              width: '50px',
              height: '50px',
              // top: '70px',
              // left: '32px'
            }}>
            <img
              src={toAbsoluteUrl(avatar)}
              alt=''
              className='h-140px p-0'
            />
          </div>
        </div>
        <div className='d-flex align-items-center flex-wrap mt-auto fs-6'>
          <div className='fw-bold text-gray-400'>{statusDesc}</div>
        </div>
      </div>
    </div >
  )
}

export { Card5 }
