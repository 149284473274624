import {useState, FC, useEffect} from 'react'

import {FilePond, registerPlugin} from 'react-filepond'
import 'filepond/dist/filepond.min.css'

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginImageEdit from 'filepond-plugin-image-edit'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

import useUtilQuery from '../../../../app/data/util/useUtilQuery'



// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageEdit,
  FilePondPluginImageTransform
)

type Props = {
  setToggle: Function
}

const UploadImage: FC<Props> = ({setToggle}) => {
  const [files, setFiles] = useState([])

  const {data: profileData, isSuccess: dataSuccess} = useUtilQuery.User_profile()

  const {mutate: updateProfilePicture, isSuccess} = useUtilQuery.Update_profile()

  const {mutateAsync:addFileToS3,data:response} =useUtilQuery.Add_File_To_S3()

  useEffect(() => {
    if (isSuccess) {
      setToggle(false)
    }
  }, [isSuccess])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-350px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-5 my-0 fw-bold'>
              <FilePond
                files={files}
                allowMultiple={false}
                instantUpload={false}
                acceptedFileTypes={['image/*']}
                name='files'
                labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                imagePreviewHeight={170}
                imageCropAspectRatio='1:1'
                imageResizeTargetWidth={200}
                imageResizeTargetHeight={200}
                stylePanelLayout='compact circle'
                styleLoadIndicatorPosition='center bottom'
                styleProgressIndicatorPosition='right bottom'
                styleButtonRemoveItemPosition='left bottom'
                styleButtonProcessItemPosition='right bottom'
                allowImageEdit={true}
                allowImageCrop={true}
                server={{
                  process: async (fieldName, file, metadata, load, error, progress, abort) => {
                    let data = null
                    if (file !== null && file !== undefined) {
                      const formData = new FormData();
                      formData.append('document', file);
                       data = await addFileToS3(formData as any)
                    }
                    load(data.url)
                    // @ts-ignore
                    updateProfilePicture({
                      name: profileData?.data?.name || 'admin',
                      profile_picture: data.url,
                    })
                  },
                }}
              />
            </div>
            {/* end::Modal body */}
            <div className='text-center mb-5'>
              <button
                type='reset'
                className='btn btn-light me-3'
                data-kt-users-modal-action='cancel'
                onClick={() => setToggle(false)}
              >
                Cancel
              </button>
            </div>
          </div>

          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {UploadImage}
