/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {toAbsoluteUrl} from '../../../helpers'
import {Link} from 'react-router-dom'

type Props = {
  className: string
  title: string
  description: string
  description2: string
  avatar: string
  linkOne?: string
  linkTwo?: string
  linkMain?: string
  bgColor?: string
}

const StatisticsWidget2: React.FC<Props> = ({
  className,
  title,
  description,
  description2,
  avatar,
  linkOne,
  linkTwo,
  linkMain,
  bgColor,
}) => {
  return (
    <div className={`card ${className}`} style={{backgroundColor: bgColor ? bgColor : 'white'}}>
      {/* begin::Body */}
      <div className='card-body d-flex align-items-center pt-1 pb-0'>
        <div className='d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2'>
          <Link
            to={linkMain || '#'}
            className={`fw-bold fs-4 mb-2 text-hover-primary ${
              description === '' ? 'mb-5 pb-5' : ''
            }`}
            style={{color: bgColor ? 'white' : '#2A3342'}}
          >
            {title}
          </Link>

          <Link
            to={linkOne || '#'}
            style={{color: description === 'Upcoming milestones' ? '#2a3342' : '#DADADC'}}
            className={`fw-semibold fs-5 text-decoration-underline ${
              description !== 'Upcoming milestones' ? 'text-muted' : ''
            }`}
          >
            {description}
          </Link>
          <Link
            to={linkTwo || '#'}
            style={{color: '#DADADC'}}
            className={`fw-semibold fs-5 text-decoration-underline ${
              description !== 'Upcoming milestones' ? 'text-muted' : ''
            }`}
            dangerouslySetInnerHTML={{__html: description2}}
          ></Link>
        </div>
        <div className='d-flex justify-content-center'>
          <img src={toAbsoluteUrl(avatar)} alt='' className='align-self-end h-100px mb-2' />
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {StatisticsWidget2}
